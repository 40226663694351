import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const fb = firebase
  .initializeApp({
    apiKey: 'AIzaSyDWlfIbdTh3nvXVEP0lhGDZR9FKk8QSz_U',
    authDomain: 'airok-rfid.firebaseapp.com',
    databaseURL: 'https://airok-rfid-default-rtdb.europe-west1.firebasedatabase.app/',
    projectId: 'airok-rfid',
    storageBucket: 'airok-rfid.appspot.com',
    messagingSenderId: '1085985763866',
    appId: '1:1085985763866:web:81611406a3a6be812617a3'
  })

// Database object
export const db = fb.firestore()

/*
// Remote Config object
export const config = fb.remoteConfig({
  setting: {
    minimumFetchIntervalMillis: 3600000
  },
  default: {
    clear_interval: 'jaa'
  }
})
config.settings.minimumFetchIntervalMillis = 3600000
config.defaultConfig = {
  welcome_message: 'Welcome'
}
 */
