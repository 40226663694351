import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    filter: {
      type: 'last',
      types: ['last', 'He', 'He_4.6', 'He_5.0', 'He_6.0', 'CO2', 'CO2 T', 'CO2 Toit', 'O2', 'O2 TOIT', 'O2+N2', 'N2', 'N2 TOIT', 'N2+H2', 'N2+H2_5%', 'N2+H2_10%', 'Ar', 'Ar_4.6', 'Ar_5.0', 'Ar+O2_2%', 'Ar+He', 'Ar+CO2', 'H2']
    }
  },
  mutations: {
    setFilterType (state, payload) {
      state.filter.type = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
