import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import { firestorePlugin } from 'vuefire'
import moment from 'moment'
import VueMoment from 'vue-moment'

require('moment/locale/et')
moment.locale('et')

Vue.use(VueMoment, { moment })

Vue.config.productionTip = false

const socketConnection = SocketIO('wss://rfid.pilv.app:39111')
Vue.use(new VueSocketIO({ connection: socketConnection }))

Vue.use(firestorePlugin)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
