<template>
  <div>
    <v-row>
      <!-- Range slider -->
      <v-col cols="12" md="7">
        <h4>Leviala</h4>
        <input type="range" min="170" max="290" step="30" v-model="RFLevel.value" style="width: 100%;" list="tickmarks" @change="setRFLevel()">
        <datalist id="tickmarks">
          <option value="170" label="Väga madal"></option>
          <option value="200" label="Madal"></option>
          <option value="230" label="Keskmine"></option>
          <option value="260" label="Hea"></option>
          <option value="290" label="Kõrge"></option>
        </datalist>
      </v-col>
      <!-- Nav -->
      <!--
      <v-col cols="12" md="3">
        <v-btn to="/database" block color="warning" class="mt-2">Andmebaas</v-btn>
      </v-col>
      -->
      <!-- Actions -->
      <v-col class="text-center">
        <v-btn-toggle mandatory dark>
          <v-btn @click="readMode = 0" depressed>
            <v-icon>mdi-gas-cylinder</v-icon> Tavarežiim
          </v-btn>
          <v-btn @click="readMode = 1; handRead()">
            <v-icon>mdi-bluetooth-connect</v-icon> Käsilugeja
          </v-btn>
          <v-btn to="/database">
            <v-icon>mdi-database</v-icon> Andmebaas
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <!-- Tag list -->
    <v-row>
      <v-col>
        <v-row>
          <!-- Title -->
          <v-col>
            <h4>Hetkel läheduses</h4>
          </v-col>
          <!-- Server error -->
          <v-col cols="4" class="text-right">
            <span v-if="!connected" class="red--text">
              <v-progress-circular :width="3" :size="20" color="red" indeterminate></v-progress-circular> Laadin andmeid...
            </span>
          </v-col>
        </v-row>

        <!-- Clear persistent tags from memory -->
        <v-row class="mb-2" v-if="persistentCount > 0">
          <v-col>
            <v-btn color="warning" @click="clearAllMemory">Puhasta nimekiri</v-btn>
          </v-col>
        </v-row>

        <!-- No markers in range -->
        <v-alert type="warning" v-if="tags.length > 1 && readMode === 0">
          <h3>Lähedusest leiti <b>{{ tags.length }}</b> RFID-märgist</h3>
          Veendu, et registreerid õige tähise, või seadista leviala vähemaks
        </v-alert>

        <!-- No markers in range -->
        <v-alert type="info" v-if="tags.length === 0">
          <h3>Lähedusest ei leitud RFID-markereid</h3>
          Alusta RFID-märgise registreerimisega või seadista leviala suuremaks
        </v-alert>

        <!-- Markers list -->
        <v-expansion-panels v-if="tags.length > 0" v-model="lockedIndex">
          <v-expansion-panel v-for="tag in orderBy(tags, 'lastSeen', -1)" :key="tag.id">
          <!--<v-expansion-panel v-for="tag in tags" :key="tag.id">-->
            <v-expansion-panel-header>
              <v-row no-gutters style="width: 100%">
                <v-col cols="4" class="text-left">
                  <small class="d-block">{{ tag.id }}</small>
                  <i v-if="tag.sn === '' && tag.type === ''">Registreerimata kiip</i>
                  <span v-else>
                    <b>{{ tag.sn }}</b> <span v-if="tag.type !== ''">({{ tag.type }})</span>
                  </span>
                </v-col>
                <v-col cols="4" class="text-left">
                </v-col>
                <v-col cols="4" class="text-right" v-if="locked !== tag.id">
                  <span :title="'Viimati nähtud ' + $moment(tag.lastSeen).format('HH:mm:ss')" class="mr-4">{{ $moment(tag.lastSeen).format('HH:mm:ss') }}</span>
                </v-col>
                <v-col cols="4" class="text-right" v-else>
                  <v-icon>mdi-pencil</v-icon>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Existing tag -->
              <v-alert v-if="tag.registered" type="info">
                <h3>RFID-märgis registreeritud andmebaasis</h3>
                {{ tag.registered }}
              </v-alert>
              <!-- Serial nr -->
              <v-text-field label="Ballooni seerianumber" v-model="tag.sn"></v-text-field>
              <!-- Type -->
              <v-select :items="$store.state.filter.types" label="Ballooni tüüp" v-model="tag.type" outlined></v-select>
              <v-btn depressed color="warning" @click="writeTagData(tag.id)">Salvesta</v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db } from '../firebase'
import moment from 'moment'
import Vue2Filters from 'vue2-filters'

export default {
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      readMode: 0, // 0 - stationary, 1 - handheld
      serviceUuid: '0000ffe0-0000-1000-8000-00805f9b34fb',
      characteristicUuid: '0000ffe1-0000-1000-8000-00805f9b34fb',
      // serviceUuid ="00002901-0000-1000-8000-00805f9b34fb";
      bluetoothDevice: null,
      myCharacteristic: null,
      RFLevel: {
        value: 0,
        options: [170, 200, 230, 290]
      },
      clientActive: true,
      heartbeatInterval: null,
      tags: [],
      locked: null,
      lockedIndex: null,
      connected: false
    }
  },
  watch: {
    lockedIndex: function () {
      if (this.lockedIndex !== undefined) {
        this.lockTag(this.tags[this.lockedIndex].id)
      } else {
        this.unlockTag()
      }
    }
  },
  computed: {
    // Count tags with persistent memory state
    persistentCount: function () {
      return this.tags.filter(obj => obj.persistence).length
    }
  },
  created () {
    document.addEventListener('visibilitychange', this.visibilityChange, false)
    this.heartbeat()
  },
  mounted () {
    // this.sortMemory()
    this.clearMemory()
  },
  sockets: {
    connect: function () {
      console.log('Server connection established')
      this.connected = true
    },
    disconnect: function () {
      console.log('Server connection lost')
      this.connected = false
    },
    tag: function (data) {
      if (this.readMode === 0) {
        this.tag(data)
        console.log('Got tag ' + data.Tag + ' from stationary reader')
      } else {
        console.log('Mode is 1 so we ignore stationary reader tag ' + data.Tag)
      }
    },
    RFLevel: function (value) {
      this.RFLevel.value = parseInt(value)
    }
  },
  methods: {
    // Connect bluetooth reader
    async handRead () {
      try {
        if (this.bluetoothDevice === null) {
          console.log('Requesting Bluetooth Device...')
          this.bluetoothDevice = await navigator.bluetooth.requestDevice({
            filters: [{ services: [this.serviceUuid] }]
            // acceptAllDevices : true
          })
        }

        console.log('> Name:             ' + this.bluetoothDevice.name)
        console.log('> Id:               ' + this.bluetoothDevice.id)
        console.log('> Connected:        ' + this.bluetoothDevice.gatt.connected)

        console.log('Connecting to GATT Server...')
        const server = await this.bluetoothDevice.gatt.connect()
        console.log('Getting Service...')

        const service = await server.getPrimaryService(this.serviceUuid)

        console.log('Getting Characteristic...')
        this.myCharacteristic = await service.getCharacteristic(this.characteristicUuid)
        console.log('myCharacteristic')
        console.log(this.myCharacteristic)

        console.log('Getting Descriptors...\')')
        const descriptors = await this.myCharacteristic.getDescriptors()
        console.log('descriptors')
        console.log(descriptors)
        for (const descriptor of descriptors) {
          switch (descriptor.uuid) {
            /*
            case BluetoothUUID.getDescriptor('gatt.client_characteristic_configuration'):
              await descriptor.readValue().then(value => {
                log('> Client Characteristic Configuration:');
                let notificationsBit = value.getUint8(0) & 0b01;
                log('  > Notifications: ' + (notificationsBit ? 'ON' : 'OFF'));
                let indicationsBit = value.getUint8(0) & 0b10;
                log('  > Indications: ' + (indicationsBit ? 'ON' : 'OFF'));
              });
              break;

            case BluetoothUUID.getDescriptor('gatt.characteristic_user_description'):
              await descriptor.readValue().then(value => {
                let decoder = new TextDecoder('utf-8');
                log('> Characteristic User Description: ' + decoder.decode(value));
              });
              break;

            case BluetoothUUID.getDescriptor('report_reference'):
              await descriptor.readValue().then(value => {
                log('> Report Reference:');
                log('  > Report ID: ' + value.getUint8(0));
                log('  > Report Type: ' + getReportType(value));
              });
              break;

            default: log('> Unknown Descriptor: ' + descriptor.uuid);
             */
          }
        }

        await this.myCharacteristic.startNotifications()

        console.log('> Notifications started')
        this.myCharacteristic.addEventListener('characteristicvaluechanged', this.handleNotifications)
      } catch (error) {
        console.log('Argh! ' + error)
      }
    },
    // Handle hand read tags
    handleNotifications (event) {
      const value = event.target.value
      let a = []
      let lastChar = '00'
      for (let i = 17; i < 29; i++) {
        lastChar = ('00' + value.getUint8(i).toString(16)).slice(-2)
        a.push(lastChar)
      }
      if (lastChar === '00') {
        for (let i = 29; i < 33; i++) {
          lastChar = ('00' + value.getUint8(i).toString(16)).slice(-2)
          a.push(lastChar)
        }
      }

      const tagEPC = a.join('').toUpperCase()
      if (this.readMode === 1) {
        this.tag({ Tag: tagEPC, persistence: true })
        console.log('Got tag ' + tagEPC + ' from handheld reader')
      } else {
        console.log('Mode is 0, so we ignore handheld read tag ' + tagEPC)
      }

      a = []
      for (let i = 0; i < value.byteLength; i++) {
        a.push('' + ('00' + value.getUint8(i).toString(16)).slice(-2))
      }
      // console.log('> ' + a.join(' '))
    },
    // Handle incoming tag data
    tag (tag) {
      let tagId = tag.Tag
      tagId = tagId.replace(/\s+/g, '')

      let tagLastSeen
      if (tag.Last === undefined) {
        // Tag data does not contain lastSeen value, lets use the time when we received data from reader
        tagLastSeen = moment().valueOf()
      } else {
        tagLastSeen = moment(tag.Last + ' GMT+0000').valueOf()
      }

      const persistence = (tag.persistence === true)

      const tagIndex = this.tags.findIndex(obj => obj.id === tagId)

      if (this.locked !== tagId) {
        if (tagIndex === -1) {
          // Newly found, add to local memory, try to get data from DB
          this.getTagData(tagId, tagLastSeen, persistence)
        } else {
          // Already in local memory, don't poll DB, update lastSeen value
          this.tags[tagIndex].lastSeen = tagLastSeen
        }
      }
    },

    // Check DB
    getTagData (tagId, tagLastSeen, persistence) {
      console.count('Tag DB count')
      const _this = this
      let tagSn = ''
      let tagType = ''
      let tagRegistered = ''

      // Check if tag exists in DB
      const dbTag = db.collection('tags').doc(tagId)
      dbTag.get().then(function (doc) {
        // Tag already exists in DB, use data from DB
        if (doc.exists) {
          tagSn = doc.data().sn
          tagType = doc.data().type
          tagRegistered = moment(doc.data().registered).format('DD.MM.YYYY HH:mm')
        }

        // Write tag to local memory
        _this.tags.push({ id: tagId, sn: tagSn, type: tagType, registered: tagRegistered, lastSeen: tagLastSeen, persistence: persistence })
        console.log(_this.tags)
      }).catch(function (error) {
        console.log('Error checking DB: ', error)
      })
    },

    // Save tag to DB
    writeTagData (id) {
      const tagIndex = this.tags.findIndex(obj => obj.id === id)
      const datetime = moment().valueOf()

      if (this.tags[tagIndex].sn !== '') {
        // Write tag to DB
        db.collection('tags').doc(id).set({
          sn: this.tags[tagIndex].sn,
          type: this.tags[tagIndex].type,
          registered: datetime
        })
          .then(() => {
            console.log('Tag ' + id + ' successfully saved to DB!')
            this.unlockTag()
          })
          .catch((error) => {
            console.error('Error writing tag to DB: ', error)
          })
      }
      // this.lockedIndex = null
    },

    // Lock tag data to local state while editing info
    lockTag (id) {
      this.locked = id
      console.log('Locked tag', id, this.lockedIndex)
    },

    // Unlock tag data so it can be updated from DB
    unlockTag () {
      this.locked = null
      console.log('Tag unlocked')
    },

    // Set antenna RF level
    setRFLevel () {
      this.$socket.emit('RFLevel', this.RFLevel.value)
    },

    // Sort memory based on lastSeen value
    sortMemory () {
      const _this = this
      setInterval(function () {
        if (_this.locked === null) {
          _this.tags = _this.tags.sort(tag => tag.lastSeen)
        }
      }, 1000)
    },

    // Clear memory of inactive tags
    clearMemory () {
      const _this = this
      setInterval(function () {
        _this.tags = _this.tags.filter(tag => tag.lastSeen > moment().subtract(3, 'seconds').valueOf() || tag.persistence || tag.id === _this.locked)
      }, 2500)
    },

    // Forcefully clear memory of all inactive tags
    clearAllMemory () {
      this.tags = this.tags.filter(tag => tag.lastSeen > moment().subtract(3, 'seconds').valueOf() || tag.id === this.locked)
    },
    visibilityChange (e) {
      // console.log('client active', !document.hidden)
      this.clientActive = !document.hidden
      this.heartbeat()
    },
    // Send regular heartbeat to server to indicate client activity
    heartbeat () {
      const _this = this
      if (this.clientActive === true) {
        _this.$socket.emit('heartbeat', 'suva')
        this.heartbeatInterval = setInterval(function () {
          _this.$socket.emit('heartbeat', 'suva')
          // console.log('sending client active heartbeat')
        }, 5000)
      } else {
        clearInterval(this.heartbeatInterval)
        // console.log('stopped sending client active heartbeats')
      }
    }
  }
}
</script>

<style>
datalist {
  display: flex;
  justify-content: space-between;
}
</style>
